import React from "react";
// import { Link } from "gatsby"

import Layout from "../components/layout";

import SEO from "../components/seo";

const Page = () => (
  <Layout>
    <SEO
      title="Premium Quality Food"
      keywords={[
        `New Hope Pet Center Premium Quality Food`,
        `New Hope`,
        `Pet Center`,
        `Pet Grooming`,
        `Pet Supplies`
      ]}
    />
    <div>
      <div className="mw8 center">
        <article className="bb b--black-10">
          <div className="db pv4 ph3 ph0-l mid-gray">
            <div className="flex flex-column flex-row-ns">
              <div className="pr3-ns mb4 mb0-ns w-100 w-40-ns">
                <img
                  src={require("../images/premium-foods.png")}
                  className="db"
                  alt="Premium Food"
                />
              </div>
              <div className="w-100 w-60-ns pl3-ns">
                <h1 className="f3 f2-ns lh-title fw9 mb3 mt0 pt3 bb-ns b--light-gray bw2">
                  Premium Quality Food
                </h1>
                <h2>
                  Our philosophy on feeding your Dog or Cat is very simple:
                </h2>
                <p className="f6 f5-l lh-copy">
                  <b>It costs you less to feed the best.</b>
                  <br />
                  <br />
                  Over the lifetime of your dog and cat, feeding quality foods
                  will keep your dogs skin, teeth, internal organs, all in the
                  best possible condition. You will save money, eliminating many
                  visits to the vet for that itchy skin, for hot spots, for that
                  urinary problem, for allergy tests.
                  <br />
                  <br />
                  Good diet can prevent MOST common health issues with dogs and
                  cats.
                </p>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  </Layout>
);

export default Page;
